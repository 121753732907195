:root{
  --primary: #1c4586;
  --priweak: #1c4586a1;
  --secondary: #fded4f;
  --info: #3498DB;
  --good: #2ECC71;
  --bad: #E74C3C;
  --warning: #F2AD00;
}

/* Hinterlegen von weiteren CSS Klassen */

/* Kopfzeile der App */
.header-content strong{
  color: #fff;
}
.header-content span{
  color: #fff;
}
