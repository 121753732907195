/* You can add global styles to this file, and also import other style files */
@import "assets/theme/variables.css";
@import "assets/theme/elements.css";
@import "assets/theme/layout.css";
@import "assets/theme/animations.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/material-icons/material-icons.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-size: 14px;
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

/*HTML Elemente*/
:root{
  --schrift: #34495e;
}


h1{
  font-family: Roboto;
}

h2{
  font-family: Roboto;
}

h3{
  font-family: Roboto;
}

h4{
  font-family: Roboto;
}

h5{
  font-family: Roboto;
}

h6{
  font-family: Roboto;
}

div{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

strong{
  font-family: Roboto-Bold !important;
  font-size: 14px !important;
  color: var(--primary);
  font-weight: normal;
}

span{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
  letter-spacing: normal;
}

p{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

ul{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

li{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

a{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--primary);
}

label{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

input{
  font-family: Roboto !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
  color: #34495e;
}

button{
  font-family: Roboto !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

button span{
  color: #fff;
}

textarea{
  font-family: Roboto !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
}

/* Überbrückung für Contentblöcke */

.contentblock strong{
  font-size: unset !important;
  color: #34495e;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-thumb{
  padding: 5px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 10px;
}

::-webkit-scrollbar-button:vertical:increment {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 3px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: var(--primary);
  -webkit-border-radius: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 50px;
  background-color: var(--primary);
  -webkit-border-radius: 3px;
}

/*Material Elemente*/

mat-icon{
  font-size: 25px !important;
}

mat-calendar-header button span{
  color: var(--schrift);
}

registrierung-info-seitenleiste{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    overflow: visible !important;
}

.werbePopup mat-dialog-container{
  padding: 0;
  background-color: transparent;
}

.wba-container mat-dialog-container{
  background-color: #e9ecee;
  overflow: hidden !important;
  padding: 18px;
}

html, body {
  height: calc(100% + env(safe-area-inset-top));
  overscroll-behavior: none !important;
}
body {
  margin: 0;
  color: #293133;
  background-color: #fff;
  overflow: hidden !important;
  overscroll-behavior: none !important;
  hyphens: auto;
}

.cdk-overlay-connected-position-bounding-box {
  top:  55.5px !important;
}

.cdk-global-scrollblock{
  overflow: hidden !important;
}

/*Style für Mat-Dialog*/
/*TODO Auslagern in Elements / Layout.css*/
.mat-dialog-container{
  width: 500px !important;
  border-radius: 10px !important;
}

.mdc-dialog__container{
  max-width: 600px !important;
}

#matDialogContent{
  padding: 10px;
}

#matDialogContentHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 10px 0;

  color: var(--primary);
}

#matDialogContentHeader mat-icon{
  cursor: pointer;
}

#matDialogContentHeader mat-icon:hover{
  color: var(--secondary);
}

#matDialogContentData{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#matDialogContentFooter{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#matDialogContentData p{
  background-color: var(--primary) !important;
  color: #FFF;
  border-radius: 10px;
  padding: 10px;
}

mat-bottom-sheet-container{
  border-radius: 10px 10px 0 0 !important;
  border-top: 3px solid var(--primary) !important;
  border-left: 3px solid var(--primary) !important;
  border-right: 3px solid var(--primary) !important;
}

.mat-dialog-content{
  overflow: visible !important;
}

.mat-form-field-wrapper{
  padding: 0 !important;
}

.mat-slide-toggle-thumb{
  background-color: var(--primary) !important;
}

.mat-form-field-appearance-fill, .mat-form-field-flex{
  border-radius: 10px !important;
}

#primary-slide div:first-child{
  background-color: var(--primary) !important;
}

.mat-select-panel{
  min-width: 106% !important;
  max-width: 40% !important;
  border-radius: 10px !important;
  margin: 12% 0px -9px 1% !important;
  /* transform: translate(-2px, 44px) !important; */
}

.mat-expansion-panel-header {
  padding: 0 10px;
  height: 50px !important;
}

.mat-expansion-panel-body {
  padding: 0 10px 16px;
}

.filterHeader:hover{
  cursor: default !important;
}

.filterHeader .mat-expansion-panel-header-description{
  margin: 5px !important;
}

.special-date span{
  color: var(--secondary) !important;
  font-weight: bold;
}

.mat-calendar-controls{
  height: 20px !important;
}

.cdk-global-scrollblock{
  position: relative;
}

.mat-menu-item{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

mat-card{
  width: calc(100% - 20px);
  background-color: #fff;
  margin: 5px;
  padding: 5px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.expansionPanelWeb{
  margin: 2px 5px 5px 5px !important;
}

mat-expansion-panel{
  margin: 6px 5px 5px 5px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.mat-elevation-z8{
  box-shadow: none;
}

.mat-table{
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 4px;
}

.faq-table thead{
  display: none !important;
}

.faq-table tr.mat-row{
  display: block;
  height: fit-content !important;
}

.faq-table tbody{
  display: block;
}

.rechtsTexteTable thead{
  display: none !important;
}

.rechtsTexteTable tr.mat-row{
  display: block;
  height: fit-content !important;
}

.rechtsTexteTable tbody{
  display: block;
}

.rechtsTexteTable mat-expansion-panel{
  box-shadow: none !important;
}

.rechtsTextePanel .mat-expansion-panel-body{
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0 0 0;
}

.mat-paginator{
  width: calc(100% - 20px);
  margin: 10px;
  background-color: var(--schrift) !important;
  border-radius: 4px;
}

mat-dialog-container{
  background-color: #fff;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2bcbba;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color: #2bcbba;
}

.mat-slide-toggle-bar, .mat-slide-toggle-thumb{
  background-color: #fc5c65;
}

/** Mat-Kalender **/

.mat-form-field-flex {
  margin: 5px;
  height: 45px;
  align-items: center !important;
}

.mat-form-field-infix{
  margin: 0 !important;
}

.mat-form-field-infix{
  margin: 0 4px 5px;
}

.mdc-icon-button{
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

.mat-form-field-infix{
  width: 140px;
  margin: 7px 0 0 0;
}

.mat-form-field-underline {
  display: none;
}

.mat-calendar-body-cell{
  border: none !important;
  box-shadow: none !important;
}

.mat-calendar-body-cell-content{
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  line-height: 1;
  border-width: none;
  border-style: none;
  border-radius: 999px;
}

.mat-calendar-body-selected{
  border-width: 1px;
  border: 3px solid var(--secondary);
  background-color: var(--primary) !important;
  color: white !important;
}

.mat-calendar-body-disabled {
  color: grey;
}

.example-custom-date-class {
  background: var(--secondary) !important;
  color: white !important;
  border-radius: 100% !important;
}

.example-custom-date-class div:first-child{
  color: white !important;
}

.mat-icon-button.mat-button-disabled{
  margin: 0 2px;
  background-color: grey;
}

.mat-datepicker-content{
  border-radius: 10px !important;
}

.mat-menu-content{
  background-color: #fff;
  padding: 10px;
}

.mat-datepicker-close-button{
  display: none;
}

.mat-divider{
  background-color: #205B9E;
}

.mat-select-min-line{
  color:black;
}

.mat-badge-content{
	width: 10px !important;
	height: 10px !important;
}

.app-content{
  height: calc(100% - 50px);
}

app-registrierung-info, app-registrierung-formular, app-registrierung-erfolgreich, app-registrierung-zusammenfassung{
  /*height: 100%;*/
  /*display: block;*/
  /*overflow-y: scroll;*/
  /*scrollbar-width: none;*/
}

/** Nachrichten **/

.snackbar{
  color: #fff;
  border-radius: 10px !important;
}

.snackbar button{
  width: 100%;
  color: #fff;
  box-shadow: none;
  border: 1px #fff solid !important;
  border-radius: 10px;
}

snack-bar-container{
  background-color: var(--primary);
  padding: 5px !important;
  color: #fff;
  border-radius: 10px !important;
}

snack-bar-container button{
  background-color: transparent;
}

.snackbar.goodMsg {
  background: var(--good);
}

.snackbar.goodMsg button{
  background: var(--good);
}

.snackbar.badMsg {
  background: var(--bad);
}

.snackbar.badMsg button{
  background: var(--bad);
}

.snackbar.neutralMsg {
  background: var(--info);
}

.snackbar.neutralMsg button{
  background: var(--info);
}

.snackbar-info button {
  background: white;
  color: #000;
}

/** Bestelltabellen **/
.menu-bezeichnung { grid-area: bezeichnung; }
.menu-einzelpreis { grid-area: einzelpreis; text-align: center; }
.menu-menge { grid-area: menge; text-align: center; }
.menu-update { grid-area: update; text-align: center; }
.menu-gesamtpreis { grid-area: gesamtpreis; text-align: center; }
.menu-beschreibung { grid-area: content; }
.menu-fehler { grid-area: fehler; }

.bestellung-kopf{
  display: grid;
  grid-template-areas:
  'bezeichnung update einzelpreis gesamtpreis';
  grid-template-columns: calc(100% - 195px) 60px 60px 60px;
  grid-gap: 5px;
}

.bestellung-zeile{
  display: grid;
  grid-template-areas: 'bezeichnung update einzelpreis gesamtpreis';
  grid-template-columns: calc(100% - 195px) 60px 60px 60px;
  grid-gap: 5px;
  padding: 5px 0 5px 0;
}

.warenkorb-kopf{
  display: grid;
  grid-template-areas:
  'bezeichnung menge update einzelpreis gesamtpreis';
  grid-template-columns: calc(100% - 250px) 60px 60px 60px 60px;
  grid-gap: 5px;
}

.speiseplanLeer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  margin: 10px 0 0 0;
}

.splanTag{
  background-color: white;
  border-radius: 0 10px 10px 10px;
  padding: 5px;
}

.horizontal1{
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.horizontal2{
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.horizontal3{
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-row: blue;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.zeilenUmbruch{
  grid-row: 2;
  margin: 30px;
}

.horizontal3::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: red; /* Hier kannst du die Hintergrundfarbe anpassen */
  z-index: -1;
  width: calc(100% + 20px); /* Breite des Pseudo-Elements entspricht der Breite des Grids plus dem Abstand */
  height: calc(100% + 20px); /* Höhe des Pseudo-Elements entspricht der Höhe des Grids plus dem Abstand */
  margin: -10px; /* Negative Margin, um das Pseudo-Element über den grid-row-gap zu legen */
}

.horizontal4, .horizontal5{
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.vertical{
  display: grid;
  grid-row-gap: 5px;
  border-radius: 0 0 10px 10px;
}

.speiseplanWba .mat-expansion-panel-body{
  padding: 0 !important;
}

.appWoche{
  display: grid;
  row-gap: 5px;
  grid-template-columns: repeat(1, 100%);
}

.appTag{
  display: grid;
  row-gap: 5px;
  grid-template-columns: repeat(1, 100%);
}

.labelHeaderCard{
  height: 30px !important;
  display: flex;
  padding: 0 20px 0 0 !important;
}

.labelHeaderCard .mat-content{
  justify-content: space-between;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface{
  padding-right: 0 !important;
  border-radius: 10px !important;
  background-color: none !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface { max-width: 100%; width: 100%; }

.warenkorbSnackbar {
  max-width: 340px !important;
  width: 340px !important;
  position: absolute !important;
  top: 45px !important;
  left: 140px !important;
}

.warenkorbSnackbar .mdc-snackbar__label {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.mdc-snackbar{
  bottom: auto !important;
  right: auto !important;
}

.vertragspartner_anrede{ grid-area: anrede }
.vertragspartner_vorname{ grid-area: vorname }
.vertragspartner_name{ grid-area: name }
.vertragspartner_strasse{ grid-area: strasse }
.vertragspartner_ortsteil{ grid-area: ortsteil }
.vertragspartner_plz{ grid-area: plz }
.vertragspartner_ort{ grid-area: ort }
.vertragspartner_telefon{ grid-area: telefon }
.vertragspartner_eMail{ grid-area: email }
.vertragspartner_gebTag{ grid-area: gebTag }

/** TODO Auslagern in Extra-Datei **/

.personenAngabenApp{
  display: grid;
  grid-template-areas:
    'anrede'
    'vorname'
    'name'
    'strasse'
    'plz'
    'ort'
    'ortsteil'
    'gebTag'
    'telefon'
    'email';
  grid-gap: 10px;
}

.personenAngabenWeb{
  display: grid;
  grid-template-areas:
    'anrede strasse strasse gebTag'
    'vorname plz ort email'
    'name ortsteil ortsteil telefon';
  grid-template-columns: 33% 10% 22% 33%;
  grid-gap: 10px;
}

.personenAngabenAbschluss{
  display: grid;
  grid-template-areas:
    'anrede strasse  gebTag'
    'vorname plz email'
    'name ort telefon'
    '. ortsteil .';
  grid-template-columns: 33% 33% 33%;
  /*grid-gap: 10px;*/
}

.vertragspartner_anrede{ grid-area: anrede }
.vertragspartner_vorname{ grid-area: vorname }
.vertragspartner_name{ grid-area: name }
.vertragspartner_strasse{ grid-area: strasse }
.vertragspartner_ortsteil{ grid-area: ortsteil }
.vertragspartner_plz{ grid-area: plz }
.vertragspartner_ort{ grid-area: ort }
.vertragspartner_telefon{ grid-area: telefon }
.vertragspartner_eMail{ grid-area: email }
.vertragspartner_gebTag{ grid-area: gebTag }

.essensteilnehmer_anrede{ grid-area: anrede }
.essensteilnehmer_vorname{ grid-area: vorname }
.essensteilnehmer_name{ grid-area: name }
.essensteilnehmer_strasse{ grid-area: strasse }
.essensteilnehmer_ortsteil{ grid-area: ortsteil }
.essensteilnehmer_plz{ grid-area: plz }
.essensteilnehmer_ort{ grid-area: ort }
.essensteilnehmer_telefon{ grid-area: telefon }
.essensteilnehmer_eMail{ grid-area: email }
.essensteilnehmer_gebTag{ grid-area: gebTag }

.rechnungskunde_anrede{ grid-area: anrede }
.rechnungskunde_vorname{ grid-area: vorname }
.rechnungskunde_name{ grid-area: name }
.rechnungskunde_strasse{ grid-area: strasse }
.rechnungskunde_ortsteil{ grid-area: ortsteil }
.rechnungskunde_plz{ grid-area: plz }
.rechnungskunde_ort{ grid-area: ort }
.rechnungskunde_telefon{ grid-area: telefon }
.rechnungskunde_eMail{ grid-area: email }
.rechnungskunde_gebTag{ grid-area: gebTag }

#einrichtungMobil{
  display: grid;
  grid-template-areas:
    'e_name e_name'
    'e_gruppe e_gruppe';
  grid-gap: 10px;
}

#einrichtungMobilFreitext{
  display: grid;
  grid-template-areas:
    'e_freitext';
  grid-gap: 10px;
}

.einrichtungWeb{
  grid-template-areas:
    'e_name e_gruppe' !important;
  grid-template-columns: 33% 33% 33%;
}

.einrichtungWebFreitext{
  grid-template-areas:
    'e_freitext' !important;
  grid-template-columns: 33% 33% 33%;
}

.einrichtung_nameEinrichtung{ grid-area: e_name }
.einrichtung_nameGruppe{ grid-area: e_gruppe }
.einrichtung_FreitextInput{ grid-area: e_freitext }

#bankverbindungMobil{
  display: grid;
  grid-template-areas:
    'b_kontoinhaber b_kontoinhaber'
    'b_iban b_iban'
    'b_bic b_bic';
  grid-gap: 10px;
}

.bankverbindungWeb{
  grid-template-areas:
    'b_kontoinhaber b_iban b_bic' !important;
  grid-template-columns: 33% 33% 33%;
}

.bankverbindung_kontoinhaber{ grid-area: b_kontoinhaber }
.bankverbindung_iban{ grid-area: b_iban }
.bankverbindung_bic{ grid-area: b_bic }

#zahlungMobil{
  display: grid;
  grid-template-areas:
    'zahlungsart zahlungsart'
    'zahlintervall zahlintervall';
  grid-gap: 10px;
}

.zahlungWeb{
  grid-template-areas:
    'zahlungsart zahlintervall' !important;
  grid-template-columns: 33% 33% 33%;
}

.zahlungsart{ grid-area: zahlungsart }
.zahlintervall{ grid-area: zahlintervall }

#anmeldungMobil{
  display: grid;
  grid-template-areas:
    'passwort passwort'
    'passwort2 passwort2';
  grid-gap: 10px;
}

.anmeldungWeb{
  grid-template-areas:
    'passwort passwort2' !important;
  grid-template-columns: 33% 33% 33%;
}

.passwort{ grid-area: passwort }
.passwort2{ grid-area: passwort2 }

#zusatzMobil{
  display: grid;
  grid-template-areas:
    's_leistungsBeginn'
    's_karte'
    's_freitext'
    's_kostenzusage'
    's_but'
    's_zahlungseinwilligung'
    's_rechPerMail'
    's_rechtsZustimmung';
  grid-gap: 10px;
}

.zusatzWeb{
  grid-template-areas:
    's_freitext s_freitext s_freitext'
    's_leistungsBeginn s_karte .'
    's_kostenzusage s_kostenzusage .'
    's_but s_but .'
    's_zahlungseinwilligung s_zahlungseinwilligung .'
    's_rechPerMail s_rechPerMail .'
    's_rechtsZustimmung s_rechtsZustimmung .'
  !important;
  grid-template-columns: 33% 33% 33%;
}

.zusatzAbschluss{
  display: grid;
  grid-template-areas:
    's_karte'
    's_leistungsBeginn'
    's_kostenzusage'
    's_but'
    's_zahlungseinwilligung'
    's_rechPerMail'
    's_rechtsZustimmung'
    's_freitext';
  grid-gap: 10px;
}

.zahlungseinwilligung{ grid-area: s_zahlungseinwilligung }
.but{ grid-area: s_but }
.leistungsBeginn{ grid-area: s_leistungsBeginn }
.kostenzusage{ grid-area: s_kostenzusage }
.karte{ grid-area: s_karte }
.freiText{ grid-area: s_freitext }
.rechPerMail{ grid-area: s_rechPerMail }
.rechtsZustimmung{ grid-area: s_rechtsZustimmung }

.registrationFeldHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registrationFeldHeader mat-icon{
  color: var(--primary);
  overflow: visible;
}

select.registrationFormFehler{
  border: 1px var(--bad) solid !important;
}

input.registrationFormFehler{
  border: 1px var(--bad) solid;
}

textarea.registrationFormFehler{
  border: 1px var(--bad) solid !important;
}

mat-checkbox.registrationFormFehler label{
  color: var(--bad);
}

.registrationBeschreibungFehler{
  color: var(--bad);
  font-size: 13px;
}

.special-date span{
  color: var(--secondary) !important;
  font-weight: bold;
}

.video-btn{
  min-width: 230px !important;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  margin: 0 0 10px 0;
  background-color: var(--secondary);
  color: white;
  text-decoration: none !important;
  border-radius: 10px;
}

.video-btn:hover{
  cursor: pointer;
}

.faqTitel strong{
  display: flex;
  min-width: 500px;
  width: fit-content;
}

.aizCard .mat-expansion-panel-body{
  padding: 0 !important;
}

#rechnungTabelle thead, #rechnungTabelle tbody{
  display: block;
}

.wartungsAbgleichDialog mat-dialog-container{
  overflow: hidden;
  width: 100% !important;
}

.bestellhistorieExpansionPanel .mat-expansion-panel-body{
  padding: 0 !important;
  background-color: #E2E9F2;
}

.bestellhistorieExpansionPanel .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover{
  background: none !important;
}

/******************************/
/*API Klassen*/

.menuZusatzBild{
  width: 40px;
}

.homeLogo{
  color: var(--primary);
}
