@import "../../assets/theme/variables.css";

button{
  height: 30px;
  width: 100%;
  margin: 10px;
  background-color: var(--secondary);
  color: #FFF;
  border: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button i{
  margin: 0 10px 0 0;
}

label{
  display: inline-block;
}

input{
  width: calc(100% - 5px);
  height: 30px;
  border: none;
  padding: 0 0 0 5px;
  background-color: #c8d6e5;
  /* box-shadow: 0px 2px 4px 1.5px rgba(0, 0, 0, 0.25) !important; */
}

textarea{
  background-color: #c8d6e5 !important;
  box-shadow: none !important;
}

input[type=date]{
  padding: 0 10px 0 10px;
  width: calc(100% - 20px);
  -webkit-appearance: none;
  -moz-appearance: none;
}

.checkbox-container {
  display: block;
  position: relative;
}

.checkbox-container input {
  opacity: 0;
}

.checkbox-checkmark {
  height: 35px;
  width: 35px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eee;
  border: 2px solid #C4C4C4;
}

.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: var(--good);
  border-color: #fff;
}

.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkbox-container .checkbox-checkmark:after {
  width: 10px;
  height: 20px;
  left: 10px;
  top: 2px;
  border: solid white;
  border-width: 0 6px 6px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#web-dialog-rechts{
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 300px;
  height: fit-content;
  max-height: 78%;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 1.5px rgba(0, 0, 0, 0.25) !important;
  z-index: 11;
  position: fixed;
  top: 45px;
  right: 0px
}
